import React, { useState, useEffect } from "react";
import styles from "./MyReviews.module.scss";
import star from "assets/img/new_star.svg";
import mobile_delete from "assets/img/mobile_delete.svg";
import star_13 from "assets/img/star_13.svg";
import new_star_empty from "assets/img/new_star_empty.svg";
import add_photo from "assets/img/add_photo.png";
import delete_image from "assets/img/delete_image.svg";
import edit from "assets/img/review_edit.svg";
import remove from "assets/img/review_delete.svg";
import EmptyProduct from "assets/img/empty_product.png";

import {
  Link,
  ModalImg,
  ModalOneButton,
  Textarea,
  ModalWindow,
  Button,
  MobileModalImg,
} from "components";
import { axiosUserProfile } from "plugins/axios";
import { Rating } from "react-simple-star-rating";
import { useMediaQuery } from "react-responsive";

const MyReviews = () => {
  const isMobile = useMediaQuery({ query: "(max-width: 767px)" });
  const [reviews, setReviews] = useState([]);
  const [count, setCount] = useState(0);
  const [selectedImage, setSelectedImage] = useState();
  const [selectedImages, setSelectedImages] = useState();
  const [modalOpen, setModalOpen] = useState(false);
  const [modalEditReview, setModalEditReview] = useState(false);
  const [editItem, setEditItem] = useState(false);
  const [Images, setImages] = useState([]);

  const [wasLastList, setWasLastList] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [previousPage, setPreviousPage] = useState(0);
  const [modalMobileOpen, setModalMobileOpen] = useState(false);

  const months = [
    "января",
    "февраля",
    "марта",
    "апреля",
    "мая",
    "июня",
    "июля",
    "августа",
    "сентября",
    "октября",
    "ноября",
    "декабря",
  ];

  const getReviews = async () => {
    let response = await axiosUserProfile.getMyReviews(currentPage);
    if (response?.data?.Error === "Invalid page.") {
      setWasLastList(true);
      return;
    }
    setPreviousPage(currentPage);
    setReviews([...reviews, ...response.results]);
    setCount(response.count);
  };

  const deleteItemPicture = async (image) => {
    setEditItem({
      ...editItem,
      Images: editItem.Images.filter((item) => item !== image),
    });
  };

  const deletePicture = async (image) => {
    setImages(Images.filter((item) => item !== image));
  };

  const editReview = async () => {
    await axiosUserProfile.editMyReview(editItem.id, {
      ...editItem,
      pic_urls: Images,
    });

    setImages([]);
    setCount(0);
    setReviews([]);
    setWasLastList(false);
    setCurrentPage(1);
    setPreviousPage(0);
    setModalEditReview(false);
  };

  const deleteReview = async (id) => {
    await axiosUserProfile.deleteMyReview(id);

    setCount(0);
    setReviews([]);
    setWasLastList(false);
    setCurrentPage(1);
    setPreviousPage(0);
  };

  const onScroll = (event) => {
    const scrollBottom =
      event.target.scrollTop + event.target.offsetHeight >=
      event.target.scrollHeight - 10;
    if (scrollBottom) {
      setCurrentPage(currentPage + 1);
    }
  };

  const zoomImg = (pic_urls, pic_url) => {
    setSelectedImage(pic_url);
    setSelectedImages(pic_urls);
    if (isMobile) {
      setModalMobileOpen(true);
    } else {
      setModalOpen(!modalOpen);
    }
  };

  useEffect(() => {
    if (!wasLastList && previousPage !== currentPage) {
      getReviews();
    }
  }, [currentPage, previousPage, wasLastList]);

  return (
    <div className={styles.container}>
      <MobileModalImg
        isOpen={modalMobileOpen}
        pic_urls={selectedImages}
        onClose={() => setModalMobileOpen(false)}
      />
      {isMobile ? (
        <ModalWindow
          isOpen={modalEditReview}
          width="100%"
          onClose={() => setModalEditReview(false)}
        >
          <div className={styles.mobile_review_modal}>
            <h1>Отзыв о товаре</h1>
            <div className={styles.heading}>Ваша оценка</div>
            <Rating
              initialValue={editItem.mark}
              transition
              titleSeparator="из"
              fillIcon={
                <img
                  src={star}
                  width={22}
                  height={22}
                  style={{ marginRight: "5px" }}
                  alt="Звезда"
                />
              }
              emptyIcon={
                <img
                  src={new_star_empty}
                  width={22}
                  height={22}
                  style={{ marginRight: "5px" }}
                  alt="Звезда"
                />
              }
              onClick={(rate) => setEditItem({ ...editItem, mark: rate })}
            />
            <div className={styles.heading}>Комментарий</div>
            <Textarea
              text={editItem.comment}
              placeholder="Ваши впечатления и пожелания"
              rows={3}
              width="100%"
              resize="none"
              change={(event) =>
                setEditItem({ ...editItem, comment: event.target.value })
              }
            />
            <div className={styles.heading}>Добавить фотографию</div>
            <div className={styles.pictures}>
              {editItem.Images?.map((image) => (
                <div key={image}>
                  {isMobile ? (
                    <img
                      className={styles.mobile_delete}
                      src={mobile_delete}
                      alt="Удалить картинку"
                      onClick={() => deletePicture(image)}
                    />
                  ) : (
                    <img
                      className={styles.delete}
                      src={delete_image}
                      alt="Удалить картинку"
                      onClick={() => deletePicture(image)}
                    />
                  )}
                  <img src={image} className={styles.img} alt="Картинка" />
                </div>
              ))}
              {Images.map((image, index) => (
                <div key={index}>
                  {isMobile ? (
                    <img
                      className={styles.mobile_delete}
                      src={mobile_delete}
                      alt="Удалить картинку"
                      onClick={() => deletePicture(image)}
                    />
                  ) : (
                    <img
                      className={styles.delete}
                      src={delete_image}
                      alt="Удалить картинку"
                      onClick={() => deletePicture(image)}
                    />
                  )}
                  <img
                    src={URL.createObjectURL(image)}
                    className={styles.img}
                    alt="Картинка"
                  />
                </div>
              ))}
              {editItem.Images?.length + Images.length < 6 && (
                <label className={styles.upload_img}>
                  <img src={add_photo} alt="Картинка" />
                  <input
                    type="file"
                    name="Фото"
                    onChange={(event) => {
                      if (event.target.files[0])
                        setImages([...Images, event.target.files[0]]);
                    }}
                  />
                </label>
              )}
            </div>
            <Button
              text="Сохранить"
              width="100%"
              padding="2vh 0"
              marginTop="4vh"
              click={editReview}
            />
          </div>
        </ModalWindow>
      ) : (
        modalEditReview && (
          <ModalOneButton
            title="Отзыв о товаре"
            description={
              <>
                <div className={styles.heading}>Ваша оценка</div>
                <Rating
                  initialValue={editItem.mark}
                  transition
                  titleSeparator="из"
                  fillIcon={
                    <img
                      src={star}
                      width={22}
                      height={22}
                      style={{ marginRight: "5px" }}
                      alt="Звезда"
                    />
                  }
                  emptyIcon={
                    <img
                      src={new_star_empty}
                      width={22}
                      height={22}
                      style={{ marginRight: "5px" }}
                      alt="Звезда"
                    />
                  }
                  onClick={(rate) => setEditItem({ ...editItem, mark: rate })}
                />
                <div className={styles.heading}>Комментарий</div>
                <Textarea
                  text={editItem.comment}
                  placeholder="Ваши впечатления и пожелания"
                  rows={3}
                  width="100%"
                  resize="none"
                  change={(event) =>
                    setEditItem({ ...editItem, comment: event.target.value })
                  }
                />
                <div className={styles.heading}>Добавить фотографию</div>
                <div className={styles.pictures}>
                  {editItem.Images.map((image) => (
                    <div key={image}>
                      <img
                        className={styles.delete}
                        src={delete_image}
                        alt="Удалить картинку"
                        onClick={() => deleteItemPicture(image)}
                      />
                      <img src={image} className={styles.img} alt="Картинка" />
                    </div>
                  ))}
                  {Images.map((image, index) => (
                    <div key={index}>
                      <img
                        className={styles.delete}
                        src={delete_image}
                        alt="Удалить картинку"
                        onClick={() => deletePicture(image)}
                      />
                      <img
                        src={URL.createObjectURL(image)}
                        className={styles.img}
                        alt="Картинка"
                      />
                    </div>
                  ))}
                  {editItem.Images.length + Images.length < 6 && (
                    <label className={styles.upload_img}>
                      <img src={add_photo} alt="Картинка" />
                      <input
                        type="file"
                        name="Фото"
                        onChange={(event) => {
                          if (event.target.files[0])
                            setImages([...Images, event.target.files[0]]);
                        }}
                      />
                    </label>
                  )}
                </div>
              </>
            }
            button={{
              title: "Сохранить",
              action: editReview,
            }}
            buttonStyle={{
              width: "100%",
              padding: `1.4vh 0`,
              fontSize: "1.8vh",
              lineHeight: "2vh",
            }}
            width="70vw"
            descriptionAlign="left"
            close={() => {
              setImages([]);
              setModalEditReview(false);
            }}
          />
        )
      )}
      {modalOpen ? (
        <ModalImg
          isOpen={modalOpen}
          pic_urls={selectedImages}
          main_img={selectedImage}
          onClose={() => setModalOpen(false)}
        />
      ) : null}
      <div className={styles.reviews_header}>
        Мои отзывы&nbsp;<span>{count}</span>
      </div>
      <div className={styles.reviews_list} onScroll={onScroll}>
        {reviews.map((review) => (
          <div key={review.id} className={styles.review}>
            <div className={styles.mobile_review}>
              <div className={styles.review_name}>
                <div>
                  <Link path={`/product/${review.product.id}`}>
                    <img
                      src={
                        review?.product?.pic_urls?.length
                          ? review?.product?.pic_urls[0].pic_url || EmptyProduct
                          : EmptyProduct
                      }
                      alt={review?.product?.name}
                      onError={(er) => (er.target.src = EmptyProduct)}
                    />
                    {!isMobile && <p>{review.product.name}</p>}
                  </Link>
                </div>
                <div className={styles.buttons}>
                  <img
                    src={edit}
                    alt="Редактировать"
                    onClick={() => {
                      setEditItem({
                        id: review.id,
                        mark: review.mark,
                        comment: review.comment,
                        Images: review.pic_urls.map((el) => el.pic_url),
                      });
                      setModalEditReview(true);
                    }}
                  />
                  <img
                    src={remove}
                    alt="Удалить"
                    onClick={() => deleteReview(review.id)}
                  />
                </div>
              </div>
              <div>
                {isMobile && <p>{review.product.name}</p>}
                <div className={styles.stars} title={review.mark}>
                  {[...Array(review.mark)].map((x, i) => (
                    <img
                      key={i}
                      src={star}
                      className={styles.star}
                      alt="Звезда"
                    />
                  ))}
                  {[...Array(5 - review.mark)].map((x, i) => (
                    <img
                      key={i}
                      src={star_13}
                      className={styles.star}
                      alt="Звезда"
                    />
                  ))}
                  <p>
                    {!isMobile && <>{localStorage.getItem("name") + ", "}</>}
                    {review.creation_date.slice(8, 10)}{" "}
                    {months[Number(review.creation_date.slice(5, 7)) - 1]}
                  </p>
                </div>
              </div>
            </div>
            {!!review.pic_urls.length && (
              <div className={styles.review_pic}>
                {review.pic_urls.map((product, index) => (
                  <img
                    key={index}
                    src={product.pic_url}
                    alt="Товар"
                    onClick={() => zoomImg(review.pic_urls, index)}
                  />
                ))}
              </div>
            )}
            {review.comment && (
              <div className={styles.review_text}>{review.comment}</div>
            )}

            <div className={styles.mobile_buttons}>
              <img
                src={remove}
                alt="Удалить"
                onClick={() => deleteReview(review.id)}
              />
              <img
                src={edit}
                alt="Редактировать"
                onClick={() => {
                  setEditItem({
                    id: review.id,
                    mark: review.mark,
                    comment: review.comment,
                    Images: review.pic_urls.map((el) => el.pic_url),
                  });
                  setModalEditReview(true);
                }}
              />
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export { MyReviews };
